
import axios from '@/http'
import { ref, computed, defineComponent, inject } from 'vue'
import { useStore } from 'vuex'
import { notifyInstance } from '@/type'

interface deviceStatus {
  storeName: string
  account: string
  devices: {
    id: string
    name: string
    addonName: string
  }[]
}

export default defineComponent({
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const storeQuantity = computed(() => store.getters.storeQuantity)
    const usedStoreQuantity = computed(() => store.getters.usedStoreQuantity)
    const deviceQuantity = computed(() => store.getters.deviceQuantity)
    const usedDeviceQuantity = computed(() => store.getters.usedDeviceQuantity)

    const storeDevices = ref<deviceStatus[]>([])

    function fetchStores() {
      axios
        .get('/store/statistics/status')
        .then((res) => {
          const data = res.data

          storeDevices.value = data.stores
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `初始失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    fetchStores()

    return {
      storeQuantity,
      usedStoreQuantity,
      deviceQuantity,
      usedDeviceQuantity,
      storeDevices
    }
  }
})
