
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: ['propTitle'],
  setup(props) {
    const title = computed(() => props.propTitle)
    return {
      title
    }
  }
})
