
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import AppHead from '@/views/member/components/head.vue'

export default defineComponent({
  components: {
    'app-head': AppHead
  },
  setup() {
    const route = useRoute()

    const headMap = new Map()
    headMap.set('Bill', '帳務中心')
    headMap.set('Monitor', '分店現況')
    const propTitle = computed(() => headMap.get(route.name) ?? '')

    return {
      propTitle
    }
  }
})
