
import axios from '@/http'
import { defineComponent, ref, computed, inject } from 'vue'
import { notifyInstance } from '@/type'
import { useStore } from 'vuex'

interface store {
  storeSale: number
  storePre: number
  deposit: number
  voidedOrder: number
  voidedPreOrder: number
  storeOnline: number
  voidedOnlineOrder: number
  storeName: string
  storePartialPickup: number
  voidedPartialPickup: number
}

export default defineComponent({
  setup() {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const view = ref(true)
    const stores = ref(new Array<store>())
    const viewType = computed(() => (view.value ? 'daily' : 'monthly'))

    function fetchStores() {
      axios
        .get(`/saleReport/statistics/${viewType.value}`)
        .then((res) => {
          const data = res.data
          Object.assign(stores.value, data.stores)
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function changeViewType(boolean: boolean) {
      view.value = boolean
      fetchStores()
    }

    fetchStores()

    function formatNumber(number: any) {
      return number === undefined ? 0 : Number(number).toLocaleString('en-US')
    }

    function getNumber(number: any) {
      if (typeof number !== 'number' || isNaN(number)) {
        return 0
      }
      return number
    }

    return {
      view,
      viewType,
      stores,
      changeViewType,
      formatNumber,
      getNumber
    }
  }
})
